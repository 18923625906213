.game {
  width: 600px;
  height: 225px;
  margin: auto;
}

#dinoOrig {
  width: 50px;
  height: 50px;
  background-image: url(img/trex.png);
  background-size: 50px 50px;
  position: relative;
  top: 150px;
}

#dino {
  width: 50px;
  height: 50px;
  background-image: url(img/bicycle.png);
  background-size: 50px 50px;
  position: relative;
  top: 150px;
}

.game {
  width: 600px;
  height: 225px;
  margin: auto;
}

#dinoOrig {
  width: 50px;
  height: 50px;
  background-image: url(img/trex.png);
  background-size: 50px 50px;
  position: relative;
  top: 150px;
}

#dino {
  width: 50px;
  height: 50px;
  background-image: url(img/bicycle.png);
  background-size: 50px 50px;
  position: relative;
  top: 150px;
}

.jump {
  animation: jump 0.3s linear;
}

@keyframes jump {
  0% {
    top: 150px;
    transform: rotate(0deg);
  }

  15% {
    top: 140px;
    transform: rotate(-15deg);
  }

  30% {
    top: 130px;
    transform: rotate(-30deg);
  }

  50% {
    top: 80px;
    transform: rotate(0deg);
  }

  70% {
    top: 130px;
    transform: rotate(15deg);
  }

  85% {
    top: 140px;
    transform: rotate(30deg);
  }

  100% {
    top: 150px;
    transform: rotate(0deg);
  }
}

#cactus {
  width: 20px;
  height: 40px;
  position: relative;
  top: 110px;
  left: 580px;

  background-image: url("img/cactus.png");
  background-size: 20px 40px;

  animation: block 1s infinite linear;
}

@keyframes block {
  0% {
    left: 580px;
  }

  100% {
    left: -5px;
  }
}

#cactus {
  width: 20px;
  height: 40px;
  position: relative;
  top: 110px;
  left: 580px;

  background-image: url("img/cactus.png");
  background-size: 20px 40px;

  animation: block 1s infinite linear;
}

@keyframes block {
  0% {
    left: 580px;
  }

  100% {
    left: -5px;
  }
}
